import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Spinner,
} from "dms-lib";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { UseApiProps, useI18n } from "compass-commons";
import { PagedOperationIncidentListDTO } from "../../models/supervisorDashboard/PagedOperationIncidentListDTO";
import QueueCard from "./QueueCard";
import { UserDTO } from "../../models/users/UserDTO";
import { incidentPriorityExtended } from "../../models/incidentsQueue/IncidentsQueueDTO";
import "./QueueAccordion.scss";

interface QueueAccordionProps {
  priority: incidentPriorityExtended;
  incidentsCount: number;
  expanded: string | false;
  hasNotification: boolean;
  handleExpand: (priority: string) => void;
  requestHandler: UseApiProps<PagedOperationIncidentListDTO>;
  currentUser: UserDTO;
}

/* Should capture from translate */
const priorityToSeverityMap: {
  [key in incidentPriorityExtended]: string;
} = {
  CRITICAL: "incidentQueue.priorityCritical",
  MAJOR: "incidentQueue.priorityMajor",
  MINOR: "incidentQueue.priorityMinor",
  WARNING: "incidentQueue.priorityWarning",
  UNKNOWN: "incidentQueue.priorityUnknown",
};

const ACCORDION_HEIGHT_PX = 48;
/* The max height depends on the number of severities displayed (excluding the expanded one) */
const ACCORDION_BLOCKS_HEIGHT =
  ACCORDION_HEIGHT_PX * (Object.keys(priorityToSeverityMap).length - 1);

const QueueAccordion = ({
  requestHandler,
  priority,
  incidentsCount,
  expanded,
  hasNotification,
  handleExpand,
  currentUser,
}: QueueAccordionProps): JSX.Element => {
  const { data, loading, error } = requestHandler;
  const [count, setCount] = useState(incidentsCount);
  const { t: translate } = useI18n();
  const isExpanded = expanded === priority;

  useEffect(() => {
    if (expanded === priority && count !== incidentsCount) {
      setCount(incidentsCount);
    }
  }, [expanded]);
  return (
    <Accordion
      TransitionProps={{ timeout: 0 }}
      expanded={isExpanded}
      onChange={() => handleExpand(priority)}
      className="incident-accordion"
      data-cr={`incident-accordion-${priority}`}
      style={{
        maxHeight: `calc(100% - ${ACCORDION_BLOCKS_HEIGHT}px)`,
      }}
    >
      <AccordionSummary>
        <div className="incident-accordion-title">
          <span>{translate(priorityToSeverityMap[priority])}</span>
          <Chip
            label={incidentsCount ?? "-"}
            color={incidentsCount && hasNotification ? "primary" : "default"}
            filled={incidentsCount && hasNotification}
            dataCr={`incident-accordion-${priority}-count`}
          />
        </div>
      </AccordionSummary>
      {loading ? (
        <div className="incident-accordion-loading">
          <Spinner size="medium" />
        </div>
      ) : (
        <AccordionDetails className="incident-accordion-content">
          {data && data.operationIncidentList?.length === 0 && (
            <div className="incident-accordion-text-centered">
              {translate("incidentQueue.noIncidents")}
            </div>
          )}
          {data &&
            data.operationIncidentList?.map((incident) => (
              <QueueCard
                key={incident.incidentId}
                incident={incident}
                currentUser={currentUser}
                translate={translate}
              />
            ))}
          {error && (
            <div className="incident-accordion-text-centered">
              {translate("incidentQueue.failedToLoadIncidents")}
            </div>
          )}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default QueueAccordion;
