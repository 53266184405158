import React, { useEffect, useRef, useState } from "react";
import { useApi } from "compass-commons";
import debounce from "lodash.debounce";
import OIMService from "../../services/OIMService";
import QueueAccordion from "./QueueAccordion";
import { useGlobalContext } from "../../contexts/GlobalContext";
import { OperationIncidentInfo } from "../../models/notification/OperationIncidentInfo";
import useCurrentUser from "../../hooks/useCurrentUser";
import { incidentPriorityExtended } from "../../models/incidentsQueue/IncidentsQueueDTO";
import "./IncidentQueue.scss";

const { OPERATION_INCIDENT_INFO_HUB } = appConfig;

const UPDATE_DEBOUNCE_TIME_MS = 500;
const UPDATE_DEBOUNCE_MAX_TIME_MS = 1000;

const ActiveIncidentsList = (): JSX.Element => {
  const globalContext = useGlobalContext();
  const { listenerService } = globalContext;

  const [expanded, setExpanded] = useState<string | false>(false);
  const expandedRef = useRef<string | false>(false);

  const { data: counterData, refetch: counterRefetch } = useApi(
    OIMService.getActiveIncidentsCount
  );
  const dataCritical = useApi(
    OIMService.getFilteredIncidents,
    "CRITICAL",
    true
  );
  const dataMajor = useApi(OIMService.getFilteredIncidents, "MAJOR", true);
  const dataMinor = useApi(OIMService.getFilteredIncidents, "MINOR", true);
  const dataWarning = useApi(OIMService.getFilteredIncidents, "WARNING", true);
  const dataUnknown = useApi(OIMService.getFilteredIncidents, "UNKNOWN", true);
  const currentUser = useCurrentUser();

  const [hasNotification, setHasNotification] = useState<{
    [key in incidentPriorityExtended]: boolean;
  }>({
    CRITICAL: true,
    MAJOR: true,
    MINOR: true,
    WARNING: true,
    UNKNOWN: true,
  });

  const getDataObject = (priority: string) => {
    switch (priority) {
      case "CRITICAL":
        return dataCritical;
      case "MAJOR":
        return dataMajor;
      case "MINOR":
        return dataMinor;
      case "WARNING":
        return dataWarning;
      case "UNKNOWN":
        return dataUnknown;
      default:
        return null;
    }
  };

  // Add notification to the queue only if it is collapsed
  const updateNotifications = (info: OperationIncidentInfo) => {
    if (expandedRef.current !== info?.priority) {
      // Add notification only to collapsed queues
      setHasNotification((prev) => ({
        ...prev,
        [info.priority]: true,
      }));
    }
  };

  const updateListWithDebounce = useRef(
    debounce(
      (info: OperationIncidentInfo) => {
        counterRefetch();
        if (expandedRef.current === info?.priority) {
          getDataObject(info.priority).refetch();
        }
      },
      UPDATE_DEBOUNCE_TIME_MS,
      { maxWait: UPDATE_DEBOUNCE_MAX_TIME_MS }
    )
  ).current;

  const handleExpand = (priority: string) => {
    if (priority !== expanded) {
      const dataObject = getDataObject(priority);
      if (hasNotification[priority] || dataObject.error) dataObject.refetch();
    }
    setExpanded((prev) => (prev === priority ? false : priority));
    expandedRef.current = expandedRef.current === priority ? false : priority;
    setHasNotification((prev) => ({ ...prev, [priority]: false }));
  };

  useEffect(() => {
    listenerService.listen(
      OPERATION_INCIDENT_INFO_HUB,
      (msg) => {
        const info: OperationIncidentInfo = JSON.parse(msg);
        updateListWithDebounce(info);
        updateNotifications(info);
      },
      "operation-incident-info"
    );

    return function cleanup() {
      listenerService.stopListening(OPERATION_INCIDENT_INFO_HUB);
    };
  }, [listenerService]);

  return (
    <div className="incident-queue-container" id="incident-queue-container">
      <div className="incident-queue-accordion">
        <QueueAccordion
          key={dataCritical.data?.operationIncidentList.length}
          priority="CRITICAL"
          incidentsCount={counterData?.[0].count}
          hasNotification={hasNotification.CRITICAL}
          expanded={expanded}
          handleExpand={handleExpand}
          requestHandler={dataCritical}
          currentUser={currentUser}
        />
        <QueueAccordion
          priority="MAJOR"
          incidentsCount={counterData?.[1].count}
          hasNotification={hasNotification.MAJOR}
          expanded={expanded}
          handleExpand={handleExpand}
          requestHandler={dataMajor}
          currentUser={currentUser}
        />
        <QueueAccordion
          priority="MINOR"
          incidentsCount={counterData?.[2].count}
          hasNotification={hasNotification.MINOR}
          expanded={expanded}
          handleExpand={handleExpand}
          requestHandler={dataMinor}
          currentUser={currentUser}
        />
        <QueueAccordion
          priority="WARNING"
          incidentsCount={counterData?.[3].count}
          hasNotification={hasNotification.WARNING}
          expanded={expanded}
          handleExpand={handleExpand}
          requestHandler={dataWarning}
          currentUser={currentUser}
        />
        <QueueAccordion
          priority="UNKNOWN"
          incidentsCount={counterData?.[4].count}
          hasNotification={hasNotification.UNKNOWN}
          expanded={expanded}
          handleExpand={handleExpand}
          requestHandler={dataUnknown}
          currentUser={currentUser}
        />
      </div>
    </div>
  );
};

export default ActiveIncidentsList;
