import React, { useState } from "react";
import { Button, Card, Chip } from "dms-lib";
import "./QueueCard.scss";
import { safelyFormatDate } from "compass-commons";
import { OperationIncidentDTO } from "../../models/supervisorDashboard/OperationIncidentDTO";
import { UserDTO } from "../../models/users/UserDTO";
import { openOperationTabIfClosed } from "../../utils/TabOpenUtils";
import OIMService from "../../services/OIMService";
import { safelyFormatDateAMPM } from "../../utils/fp";

interface QueueCardProps {
  incident: OperationIncidentDTO;
  currentUser: UserDTO;
  translate: any;
}

const QueueCard = ({
  incident,
  currentUser,
  translate,
}: QueueCardProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false);

  const getIncidentStatus = () => {
    if (incident.assignedToUserId) {
      return currentUser?.userId === incident.assignedToUserId.toString()
        ? "incident-card-container-active"
        : "incident-card-container-assigned";
    }
    return "incident-card-container-default";
  };

  const handleAssignToMe = async () => {
    openOperationTabIfClosed();
    OIMService.assignIncidentToUser(incident.id, currentUser?.userId);
  };

  return (
    <Card
      expanded={expanded}
      setExpanded={setExpanded}
      fullWidth
      dataCr={`incident-card-${incident.id}`}
      className={`${getIncidentStatus()} incident-card-${incident.priority}`}
      cardTitle={
        <div className="incident-card-title-container">
          <div className="incident-card-title-info">
            <span>{incident.incidentDescription}</span>
          </div>
          <div className="incident-card-title-assigned">
            <Chip
              color="default"
              label={translate("incidentQueue.assignedChip")}
              size="medium"
            />
          </div>
          <div className="incident-card-title-time">
            {safelyFormatDateAMPM(incident.incidentTimestamp)}
          </div>
        </div>
      }
      cardContent={
        <>
          <hr className="incident-card-divider" />
          <div className="incident-card-content-row">
            <span className="incident-card-content-row-subtitle">
              {translate("incidentQueue.locationTitle")}
            </span>
            <span className="incident-card-content-row-title">
              {incident.siteName}
            </span>
          </div>
          <hr className="incident-card-divider" />
          <div className="incident-card-content-row">
            <span className="incident-card-content-row-subtitle">
              {translate("incidentQueue.resourceTitle")}
            </span>
            <span className="incident-card-content-row-title">
              {incident.resourceMappingName || "Not defined"}
            </span>
          </div>
          <hr className="incident-card-divider" />
        </>
      }
      cardActions={
        <div className="incident-card-actions">
          <Button
            color="primary"
            variant="contained"
            disabled={!!incident.assignedToUserId}
            onClick={handleAssignToMe}
            dataCr="incident-card-assign-button"
          >
            {translate("incidentQueue.responseButton")}
          </Button>
        </div>
      }
      isExpansible
    />
  );
};

export default QueueCard;
